<template>
    <div class="divAll" v-show="!this.$webData.pcAndNpc">
        <div class="common-layout">
            <el-container>
                <el-header>
                    <div style="position: fixed; top: 0; left: 0; right: 0; background-color: #ffffff">
                        <el-row style="height: 70px; padding-left: 10px; padding-right: 10px; line-height: 70px; width: 80%; margin: 0 auto;">
                            <el-col :span="12">
                                <img src="https://xs-paper-img.oss-cn-beijing.aliyuncs.com/Paper%E5%89%AF%E6%9C%AC.png"
                                     style="width: 40px; height: 40px; margin-top: 15px; float: left; margin-right: 5px">
                                <div class="grid-content ep-bg-purple-light"
                                     style="float: left; font-size: 20px; font-weight: bold">
                                    Paper
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-row style="text-align: center">
                                    <el-col :span="8" style="">
                                        <span @click="index()" style="cursor: pointer;">首页</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span style="cursor: pointer;" @click="concerning()">关于Paper</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span style="cursor: pointer; border-bottom: 10px solid #000000; border-radius: 5px; padding-bottom: 5px;">联系我们</span>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </div>
                </el-header>
                <el-main>
                    <div style="width: 70%; margin: 0 auto; margin-top: 20px">
                        <img src="@/static/img/img.png" style="width: 60%; float: left; padding-right: 5%">
                        <div style="width: 35%; float: right;">
                            <p style="font-weight: bold; font-size: 18px">旭杉（沈阳市）网络科技有限公司</p>
                            <p>邮编: 110100</p>
                            <p>Paper官方邮箱: xspaperapp@163.com</p>
                            <p>违法信息举报: 028-63239000（7*24小时)</p>
                            <p>意见邮箱: xspaperapp@163.com</p>
                            <p>中国互联网举报中心: 12377</p>
                        </div>
                    </div>
                </el-main>
                <el-footer>
                    <div>
                        <div style="text-align: center; font-size: 12px; position: fixed; bottom: 0px; left: 0; right: 0; background-color: black; color: #ffffff; padding-top: 20px; padding-bottom: 20px">
                            旭杉（沈阳市）网络科技有限公司<br/>
                            <a href="https://beian.miit.gov.cn" style="color: #ffffff">辽ICP备2023013240号 </a>
                            <a href="https://beian.miit.gov.cn" style="color: #ffffff"> 增值电信业务经营许可证: 辽B2-20240456号 </a>
                            <br/>
                            <a href="https://ys.xushanpaper.com/" style="color: #ffffff"> Paper隐私协议 </a>
                            <span style="color: #ffffff">Paper版本: 1.0.0</span>
                        </div>
                    </div>
                </el-footer>
            </el-container>
        </div>
    </div>
</template>
<script>
import router from "@/router";

export default {
    data() {
        return {}
    },
    methods: {
        index() {
            router.push('/')
        },
        concerning() {
            router.push('/concerning')
        }
    },
}
</script>
<style scoped>

/* 最外层div样式 */
a {
    text-decoration: none; /* 去除默认的下划线 */
    outline: none; /* 去除旧版浏览器的点击后的外虚线框 */
    color: #000; /* 去除默认的颜色和点击后变化的颜色 */
}

a:hover {
    text-decoration: underline;
}
</style>