<template>
    <router-view />
</template>

<script>
import router from "@/router";
    export default {
        data() {
            return {
                count: 0,
            };
        },
        created() {
            this.count = parseInt(localStorage.getItem("count")) || 0;
            router.push('/')
        },
    }
</script>

<style>
    /* body{
        margin: 0px;
        padding: 0px;
    } */
    html,
    body,
    #app {
        height: 100%;
        margin: 0;
        padding: 0;
    }
</style>