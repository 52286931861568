import {createRouter, createWebHistory} from 'vue-router'
import index from '../views/paperIndex.vue'
import concerning from '../views/concerning.vue'
import contactPaper from '../views/contactPaper.vue'
import agreement from '../views/agreement.vue'

const routerHistory = createWebHistory("/")

const router = createRouter({
  history: routerHistory,
  mode: 'history',
  base: '/',
  /*
   *   在这里和vue2一样写路由配置
  */
  routes:[
    {
      path: '/',
      name: 'index',
      component: index
    },{
      path: '/concerning',
      name: 'concerning',
      component: concerning
    },{
      path: '/contactPaper',
      name: 'contactPaper',
      component: contactPaper
    },{
      path: '/agreement',
      name: 'agreement',
      component: agreement
    }
  ]
})

export default router
