<template>
    <div class="divAll" v-show="!this.$webData.pcAndNpc">
        <div style="background-color: #ffffff">
            <div class="common-layout">
                <el-container>
                    <el-header>
                        <div style="position: fixed; top: 0; left: 0; right: 0; background-color: #ffffff">
                            <el-row style="height: 70px; padding-left: 10px; padding-right: 10px; line-height: 70px; width: 80%; margin: 0 auto;">
                                <el-col :span="12">
                                    <img src="https://xs-paper-img.oss-cn-beijing.aliyuncs.com/Paper%E5%89%AF%E6%9C%AC.png"
                                         style="width: 40px; height: 40px; margin-top: 15px; float: left; margin-right: 5px">
                                    <div class="grid-content ep-bg-purple-light"
                                         style="float: left; font-size: 20px; font-weight: bold">
                                        Paper
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <el-row style="text-align: center">
                                        <el-col :span="8" style="">
                                            <span @click="index()" style="cursor: pointer;">首页</span>
                                        </el-col>
                                        <el-col :span="8">
                                            <span style="cursor: pointer; border-bottom: 10px solid #000000; border-radius: 5px; padding-bottom: 5px;">关于Paper</span>
                                        </el-col>
                                        <el-col @click="contactPaper()" :span="8" style="cursor: pointer;">联系我们
                                        </el-col>
                                    </el-row>
                                </el-col>
                            </el-row>
                        </div>
                    </el-header>
                    <el-main>
                        <div>
                            <div style="margin: 0 auto; width: 50%">
                                <div style="text-align: center; font-size: 30px; font-weight: bold; margin-top: 50px; margin-bottom: 50px">
                                    关于Paper
                                </div>
                                <p style="margin-bottom: 20px; letter-spacing: 3px;line-height: 2;">
                                    Paper是“旭杉（沈阳市）网络科技有限公司”制作的一款基于当前快节奏生活推出的一款交友App,
                                    Paper并称着用户至上的原则, 打造当代年轻人的私人空间。 Paper成立于2024年,
                                    Paper想要打造一个全新的快捷的社交平台, 最终目的是让年轻人告别繁琐的社交,
                                    不在不必要的事情上消耗情绪。
                                    在Paper中您可以尽情的诉说不悦、表达自己、结交新朋友。在Paper中您只是您自己, 您是最尊贵的,
                                    您不是任何人的附属品, 可以卸下一切枷锁无忧无虑。在当前快节奏的生活中, 没有那么多精力浪费掉,
                                    在Paper App中可以用低成本获得一段高质量的关系。愿所有年轻人压力小一点！
                                </p>
                            </div>
                            <div style="margin: 0 auto; width: 50%;">
                                <div style="text-align: center; font-size: 30px; font-weight: bold; margin-top: 50px; margin-bottom: 50px">
                                    关于我们
                                </div>
                                <p style="margin-bottom: 20px; letter-spacing: 3px;line-height: 2;">
                                    PaperApp - 打造全新交友模式 于2024年上架<br>
                                    PaperApp 想要成为最受年轻人喜爱的App之一<br>
                                    PaperApp由“旭杉（沈阳市）网络科技有限公司开发”<br>
                                </p>
                            </div>
                        </div>
                    </el-main>
                    <el-footer>
                        <div>
                            <div style="text-align: center; font-size: 12px; position: fixed; bottom: 0px; left: 0; right: 0; background-color: black; color: #ffffff; padding-top: 20px; padding-bottom: 20px">
                                旭杉（沈阳市）网络科技有限公司<br/>
                                <a href="https://beian.miit.gov.cn" style="color: #ffffff">辽ICP备2023013240号 </a>
                                <a href="https://beian.miit.gov.cn" style="color: #ffffff"> 增值电信业务经营许可证: 辽B2-20240456号 </a>
                                <br/>
                                <a href="https://ys.xushanpaper.com/" style="color: #ffffff"> Paper隐私协议 </a>
                                <span style="color: #ffffff">Paper版本: 1.0.0</span>
                            </div>
                        </div>
                    </el-footer>
                </el-container>
            </div>
        </div>
    </div>
</template>
<script>
import router from "@/router";

export default {
    data() {
        return {}
    },
    methods: {
        index() {
            router.push('/')
        },
        contactPaper() {
            router.push('/contactPaper')
        }
    },
}
</script>
<style scoped>

/* 最外层div样式 */
a {
    text-decoration: none; /* 去除默认的下划线 */
    outline: none; /* 去除旧版浏览器的点击后的外虚线框 */
    color: #000; /* 去除默认的颜色和点击后变化的颜色 */
}

a:hover {
    text-decoration: underline;
}
</style>