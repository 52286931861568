<template>
    <div class="divAll" v-show="!this.$webData.pcAndNpc">
        <!-- 标题栏默认为暗黑色以深蓝色和黑色作为背景色，做半透明处理 打开更多改为黑色不透明 -->
        <div :style="{'backgroundColor': this.indexYN ? '#ff4e50' : '#fff', 'color': this.indexYN ? '#ffffff' : '#000000'}">
            <div :style="{'background': this.indexYN ? 'rgba(227, 227, 227, 0.2)' : '#fff'}">
                <el-row style="height: 70px; padding-left: 10px; padding-right: 10px; line-height: 70px; width: 80%; margin: 0 auto;">
                    <el-col :span="12">
                        <img src="@/static/img/PaperLogo.png"
                             style="width: 40px; height: 40px; margin-top: 15px; float: left; margin-right: 5px">
                        <div class="grid-content ep-bg-purple-light"
                             style="float: left; font-size: 20px; font-weight: bold">
                            Paper
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <el-row style="text-align: center">
                            <el-col :span="8" style="">
                                <span style="cursor: pointer; border-bottom: 10px solid #ffffff; border-radius: 5px; padding-bottom: 5px;">首页</span>
                            </el-col>
                            <el-col :span="8" @click="concerning()" style="cursor: pointer;">关于Paper</el-col>
                            <el-col :span="8" @click="contactPaper()" style="cursor: pointer;">联系我们</el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
        </div>
        <el-carousel @mousewheel="rollScroll($event)" trigger="click" direction="vertical" :autoplay="false" style=""
                     :height="screenheightb+'px'" ref="vertical">
            <!-- 第一页 -->
            <el-carousel-item key="0"
                              style="background-image: linear-gradient(to top, #f9d423 0%, #ff4e50 100%); height: 100%; width: 100%;">
                <div style="height: 100%;">
                    <h2 style="padding: 0; text-align: center; margin: 10% 0 4%;color: #fff;">
                        Paper App
                    </h2>
                    <h3 style="text-align: center; font-weight: 900; font-size: 30px; font-family: 幼圆; color: #fff; margin: 0px; padding: 0px;">
                        告别繁琐社交, 打造全新交友模式
                    </h3>
                    <p style="text-align: center; color: #fff;margin: 0px; padding: 0px; margin-top: 4%;">
                        快捷 / 真实 / 高质 / 创新
                    </p>
                    <div @click="download()"
                         style="margin: 0px auto; border: 1px solid black; margin-top: 4%; width: 90px; padding: 10px; border-radius: 10px;">
                        安卓下载
                        <el-icon>
                            <Download/>
                        </el-icon>
                    </div>
                </div>
            </el-carousel-item>
            <!-- 第二页 -->
            <el-carousel-item key="1" style="">
                <div style="margin: 0 auto;border: solid 3px transparent; border-radius: 10px; background-image: linear-gradient(#fff, #fff), linear-gradient(to top, #f9d423, #ff4e50); background-origin: border-box; background-clip: content-box, border-box; margin-top: 3%; width: 60%;">
                    <!-- 标题和下载按钮 -->
                    <div style="margin-bottom: 20px;">
                        <h2 style="margin: 0px; padding: 0px; text-align: center; margin-bottom: 4%; color: black;">
                            Paper App</h2>
                        <div @click="download()"
                             style="margin: 0px auto; border: 1px solid black; margin-top: 4%; width: 90px; padding: 10px; border-radius: 10px;">
                            安卓下载
                            <el-icon>
                                <Download/>
                            </el-icon>
                        </div>
                    </div>
                    <!-- img图片样式 -->
                    <div style="width: 100%; margin: 0px auto; margin-bottom: 10px; margin-top: 10px;">
                        <div style="width: 60%; margin: 0px auto;">
                            <img src="../static/img/n.png" style="width: 20%; border-radius: 10px;"/>
                            <img src="../static/img/tow1.png" style="width: 60%; margin-bottom: 10%;"/>
                            <img src="../static/img/w.png" style="width: 20%; border-radius: 10px;"/>
                        </div>
                    </div>
                    <div style="width: 100%;">
                        <div style="width: 20%; margin: 0px auto;">
                            一键告别繁琐社交, Paper为所有人打造全新的社交体验, 建立专属于个人的Paper空间, 让他(她)们作为你的情感树洞
                        </div>
                    </div>
                    <!-- img图片样式 -->
                    <div style="width: 100%; margin: 0px auto; margin-top: 10px; margin-bottom: 6px;">
                        <div style="width: 60%; margin: 0px auto;">
                            <img src="../static/img/w.png" style="width: 20%; border-radius: 10px;"/>
                            <img src="../static/img/tow1.png" style="width: 60%; margin-bottom: 10%;"/>
                            <img src="../static/img/n.png" style="width: 20%; border-radius: 10px;"/>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
            <el-carousel-item key="2">
                <div style="border: solid 3px transparent; border-radius: 10px; background-image: linear-gradient(#fff, #fff), linear-gradient(to top, #f9d423, #ff4e50); background-origin: border-box; background-clip: content-box, border-box; margin: 3% auto 0;width: 60%;">
                    <!-- 标题和下载按钮 -->
                    <div style="margin-bottom: 20px;">
                        <h2 style="margin: 0px; padding: 0px; text-align: center; margin-bottom: 4%; color: black;">
                            Paper App</h2>
                        <div @click="download()"
                             style="margin: 0px auto; border: 1px solid black; margin-top: 4%; width: 90px; padding: 10px; border-radius: 10px;">
                            安卓下载
                            <el-icon>
                                <Download/>
                            </el-icon>
                        </div>
                    </div>
                    <!-- img图片样式 -->
                    <div style="width: 100%; margin: 0px auto; margin-bottom: 10px; margin-top: 10px;">
                        <div style="width: 60%; margin: 0px auto;">
                            <img src="../static/img/n.png" style="width: 20%; border-radius: 10px;"/>
                            <img src="../static/img/tow1.png" style="width: 60%; margin-bottom: 10%;"/>
                            <img src="../static/img/w.png" style="width: 20%; border-radius: 10px;"/>
                        </div>
                    </div>
                    <div style="width: 100%;">
                        <div style="width: 20%; margin: 0px auto;">
                            Paper拥有交换纸条、语音匹配、交换纸条等功能，更多功能欢迎各位尊贵的用户提出意见，我们将第一时间采纳
                        </div>
                    </div>
                    <!-- img图片样式 -->
                    <div style="width: 100%; margin: 0px auto; margin-top: 10px; margin-bottom: 6px;">
                        <div style="width: 60%; margin: 0px auto;">
                            <img src="../static/img/w.png" style="width: 20%; border-radius: 10px;"/>
                            <img src="../static/img/tow1.png" style="width: 60%; margin-bottom: 10%;"/>
                            <img src="../static/img/n.png" style="width: 20%; border-radius: 10px;"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div style="text-align: center; font-size: 14px; position: fixed; bottom: 10px; left: 0; right: 0;">
                        旭杉（沈阳市）网络科技有限公司<br/>
                        <a href="https://beian.miit.gov.cn">辽ICP备2023013240号 </a>
                        <a> 增值电信业务经营许可证: 辽B2-20240456号 </a>
                        <br/>
                        <a href="https://ys.xushanpaper.com/" style=""> Paper隐私协议 </a>
                        <span>Paper版本: 1.0.0</span>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
    <div class="divAll" v-show="this.$webData.pcAndNpc"
         style="background-image: linear-gradient(to top, #f9d423 0%, #ff4e50 100%); height: 100%; width: 100%">
        <div style="">
            <!-- 横向标题 -->
            <div style="background: rgba(227, 227, 227, 0.4); height: 80px; color: #ffffff; font-weight: bold">
                <div style="width: 40px; text-align: center; font-weight: bolder; padding-top: 10px; margin-left: 10px; float: left;">
                    <img src="@/static/img/PaperLogo.png" style="width: 40px; height: 40px">
                    Paper
                </div>
                <div style="float: right; margin-right: 10px; line-height: 80px; color: #ffffff; font-weight: bold">
                    联系我们
                </div>
            </div>
            <div>
                <h4 style="font-weight: bold; text-align: center; color: #ffffff">Paper</h4>
                <div style="width: 160px; margin: 0 auto;">
                    <img src="../static/img/shouye.jpg" style="width: 160px; border-radius: 10px">
                </div>
                <div style="font-size: 10px; text-align: center; color: #ffffff; padding-bottom: 6px">告别繁琐社交,
                    打造全新交友模式 来Paper寻找哪个有缘的他（她）
                </div>
            </div>
            <div @click="download()"
                 style="padding-top: 10px; padding-bottom: 10px; width: 40%; text-align: center; border-radius: 10px; background: linear-gradient(-45deg, #FDB078, #F36879); color: #ffffff; margin: 0 auto;">
                安卓客户端下载
            </div>
            <div>
                <div style="text-align: center; font-size: 14px; position: fixed; bottom: 10px; left: 0; right: 0;">
                    旭杉（沈阳市）网络科技有限公司<br/>
                    <a href="https://beian.miit.gov.cn">辽ICP备2023013240号</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import router from '../router/index.js'
import axios from 'axios'

export default {
    data() {
        return {
            screenheighta: 30,
            screenheightb: document.documentElement.clientHeight - 70,
            scroll: '0',
            // 判断是否在首页
            indexYN: true,
            // 当前页数
            pageindex: 0
        }
    },
    created() {
        // 用于判定是否是pc 为false的时候是pc 反之是手机端
        if (this._isMobile()) {
            this.$webData.pcAndNpc = true
        } else {
            this.$webData.pcAndNpc = false
        }
        // alert(this.$webData.pcAndNpc)
    },
    methods: {
        //判断滚动方向，因为此demo中只有四页，故边界处理为 0 与 3
        rollScroll(event) {
            let _that = this;
            // chrome、ie使用的wheelDelta，火狐使用detail
            let scrollVal = event.wheelDelta || event.detail;
            // 节流
            if (!_that.timeOut) {
                _that.timeOut = setTimeout(() => {
                    _that.timeOut = null;
                    if (scrollVal < 0 && _that.pageindex !== 2) {
                        _that.pageindex++
                        _that.$refs.vertical.setActiveItem(_that.pageindex)
                        if (_that.pageindex === 0) {
                            this.indexYN = true
                            return
                        }
                        this.indexYN = false
                    } else if (scrollVal > 0 && _that.pageindex !== 0) {
                        _that.pageindex--
                        _that.$refs.vertical.setActiveItem(_that.pageindex)
                        if (_that.pageindex === 0) {
                            this.indexYN = true
                            return
                        }
                        this.indexYN = false
                    }
                }, 50);
            } else {
            }
        },


        // 判断是不是pc端
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },

        //关于我们()
        concerning() {
            router.push('/concerning')
        },
        contactPaper() {
            router.push('/contactPaper')
        },
        download() {
            var a = document.createElement('a') // 创建一个<a></a>标签
            a.href = 'https://gdown.baidu.com/appcenter/pkg/upload/01cee70239f255356df60c1e3be24b78'         //重点（如测试发现下载文件不存在/找不到，检查路径）
            a.download = 'xushanpaperapp.1.0.0.apk'         // 设置下载文件文件名
            a.style.display = 'none'           // 隐藏a标签
            document.body.appendChild(a)       // 将a标签追加到文档对象中
            a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
            a.remove()
        }
    },
}
</script>
<style scoped>

/* 最外层div样式 */
a {
    text-decoration: none; /* 去除默认的下划线 */
    outline: none; /* 去除旧版浏览器的点击后的外虚线框 */
    color: #000; /* 去除默认的颜色和点击后变化的颜色 */
}

a:hover {
    text-decoration: underline;
}
</style>