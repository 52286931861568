import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
// import Antd from 'ant-design-vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 全局变量
import { webData } from './utils/webData.js'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.config.globalProperties.$webData = webData
app.use(ElementPlus).use(router).mount('#app')