<template>
    <div>
        <h3 style="text-align: center;" class="ziti">Paper App隐私政策</h3>
        <h5 style="text-align: center; margin-top: 10px;">
            发布时间: 2000年1月1日<br />
            生效时间: 2000年1月1日
        </h5>
        <div style="text-align: center; margin-top: 15px;">
            <text style="font-weight: bold;">前言</text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                “Paper”的运营主体是旭杉（沈阳市）网络科技有限公司开发（注册及联系地址为：中国（辽宁省沈阳市）浑南区创新路510-9号 9幢26楼2室）（以下简称"我们"）深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠，我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则，目的明确原则，选择同意原则，最少够用原则，确保安全原则，主体参与原则，公开透明原则等等。同时我们承诺，我们将按业界成熟的安全解决方案，采取相应的安全保护措施来保护您的个人信息。鉴此，我们制定本《隐私政策》（下称"本政策／本隐私政策"）并提醒您：
                本政策适用于Paper软件及相关服务。<br />
                <text style="font-weight: bold;">需要特别说明的是，本政策不适用于其他第三方向您提供的服务，也不适用于已另行独立设置隐私权政策的产品或服务。</text><br />
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                <text style="font-weight: bold; border-bottom: 1px solid black;">在使用Paper软件或服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后使用相关产品或服务。若您拒绝本政策，仅能进入访客态进行浏览。为保障软件及服务的安全运行，Paper在相应功能点触发时会收集相关设备信息及权限。</text>如对本政策内容有任何疑问、意见或建议，您可通过Paper提供的各种联系方式与我们联系。
            </text>
        </div>
        <div style="margin-top: 15px;">
            <text>
                <text style="font-weight: bold;">本隐私政策部分将帮助您了解一下内容：</text>
            </text>
        </div>
        <div>
            <text>
                一、我们如何收集和使用您的个人信息
                二、我们如何使用 Cookie 和同类技术
                三、我们如何共享、转让、公开披露您的个人信息
                四、我们如何保护您的个人信息
                五、您如何管理您的个人信息
                六、我们如何处理未年人的个人信息
                七、我们如何存储您的个人信息
                八、本隐私政策如何更新
            </text>
        </div>
        <div style="margin-top: 15px; font-weight: bold;">
            <text>
                一、我们如何收集和使用您的个人信息
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。包括姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、参禅i西南西、征信信息、行踪轨迹、住宿信息、健康胜利inxi、交易信息、14周岁以下（含）儿童的个人信息等（我们将在本隐私协议中对具体个人敏感信息以粗体进行显著标识）
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em; font-weight: bold;">
            <text>
                个人信息以及个人敏感信息包含的内容均出自于 GB /T35273《个人信息安全规范》。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em; font-weight: bold;">
            <text>
                您理解并同意：
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em; font-weight: bold;">
            <text>
                1、我们致力于打造多样的产品服务以满足您的需求。因我们向您提供的产品服务种类众多，且不同用户选择使用的具体产品服务范围存在差异，相应的，收集使用的个人信息类型、范围等会有所区别，请以具体的产品服务功能为准；
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em; font-weight: bold;">
            <text>
                2、我们可能将通过某些功能所收集的信息用于我们的其他服务。如我们使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前，再次向您告知并征得您的明示同意。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em; font-weight: bold;">
            <text>
                3、为给您带来更好的产品或服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。在此过程中，如果您有任何疑问、意见或建议的，您可通过我们提供的各种联系方式与我们联系，我们会尽快为您作出解答。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                我们所提供产品或服务的功能分别收集的个人信息类型，以及收集、使用个人信息的规则（例如收集和使用个人信息的目的、方式）如下：
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                1、当您注册“Paper”服务时，您可以通过手机号码创建账号，我们会收集您的手机号、昵称、头像、性别、出生日期用于为您创建“Paper”账号并为您提供相关服务；此外，您可以使用第三方账号 QQ 和微信来进行注册、登录，但需要授权我们获取您在 QQ 和微信的注册账号相关信息（头像、昵称及您授权的其他信息）及手机号码（用来绑定账号），用于为您创建“Paper”的账号并为您提供相关服务；在注册与服务的过程中，如您需要更正您的生日、性别信息，我们会收集您的身份认证信息（姓名、身份证号、生物识别特征）以帮助您完成信息更正。如果您提供以下信息补全个人资料：自我介绍、个性标签、星座、常居住城市，将有助于我们给您提供更好的服务和体验，但如果您不提供这些信息，将不会影响使用“Paper”产品或服务的基本功能。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                2、当您使用动态、聊天室及其他信息发布公开发布信息，包括发布图文音频、发表评价、关注、分享、点赞功能时，您需要开启相册、相机、麦克风权限来拍摄照片或语音交互，如涉及到位置服务，您还需要相应的开启地理位置权限。当您创建聊天室房间时，您还需要一局法律要求向我们提供相应的身份信息（真是姓名和身份证号）以完成实名认证。请注意，您公开发布的任何信息中可能会设计您或其他人的个人信息甚至个人敏感信息，如您发布动态时选择上传包含个人信息的图片，请您慎重考虑，是否使用我们的服务共享甚至公开分享相关信息。您上传的动态招牌你、伶伦、点赞等信息都会存储在我们的服务器中，因为存储是实现这一功能所必须的。我们会以加密的方式存储，您可以随时删除这些信息。除非敬您自主选择或遵从相关法律要求,我们不会对以上提供的信息,胡总和将其用于该功能以外的其他用途。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                3、当您选择雨您感兴趣的人建立好友关系、通过我们提供的功能组件向其他第三方分享信息时，我们会手机包括您的好友关系历史在内的服务信息用于上述功能以及我们明确告知的目的。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                4、当您支付充值平台虚拟货币时，我们会手机您的微信支付信息、支付宝信息。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                5、当您雨我们联系或者提出争议纠纷处理时，我们为保障您的账号以及系统安全，我们需要您提供必要的个人信息已核实您的用户身份。我们会保存您的通话内容（包括但不限于账号信息、您为了证明相关实时提供的其他信息，或者您留下的联系方式信息），以便于您联系或帮助您解决问题。或记录相关问题的处理方案以及结果。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                6、为及时向您推送相关服务的各类消息通知(如聊天消息通知、您关注的用户动态)，我们需要获取您的手机通知权限，如您不想收到相关上述通知，您可以在设置中进随时关闭我们使用您手机通知的权限，
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                7、您知悉并同意我们需要您开启自启动以保障消息能及时送达。您可以在设备设置中进行应用启动管理，但关闭后可能导致您的消息接收延迟。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                8、为实现信息分享、参加相关活动等目的所必需，我们可能会调用剪切板并使用与功能相关的最小必要信息(口令、链接、统计参数等)。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                9、若您提供的信息中含有其他用户的个人信息，在向“Paper”提供这些个人信息之前，您需确保您已经取得合法的授权。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                10、若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                11、若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人信息，并向您告知共享的信息内容，且涉及敏感信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任;同时，我们的专业安全团队对个人信息会进行安全防护(包括敏感信息分级分类、敏感信息使用的访问控制、敏感信息加密存储等)。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                12、征得授权同意的例外

            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意:
            </text>
        </div>
        <div style="margin-left: 4em;">
            <text>
                (1)与我们履行法律法规规定的义务相关的；
                (2)与国家安全、国防安全有关的；
                (3)与公共安全、公共卫生、重大公共利益有关的；
                (4)与犯罪侦查、起诉、审判和判决执行等有关的；
                (5)出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
                (6)所收集的个人信息是您自行向社会公众公开的；
                (7)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
                (8)根据您的要求签订合同所必需的；
                (9)用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
                (10)为合法的新闻报道所必需的；
                (11)学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的;
                (12)法律法规规定的其他情形。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善“Paper”的内容和布局，以及改进我们的产品和服务(包括使用匿名数据进行机器学习或模型算法训练)，则此类处理后数据的使用无需另行向您通知并征得您的同意。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                如我们停止运营“Paper”软件及相关服务，我们将立即停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您并按相关法律法规的规定对所持有的个人信息进行删除或匿名化处理。
            </text>
        </div>
        <div style="margin-top: 15px; font-weight: bold;">
            <text>
                二、我们如何使用 Cookie 和同类技术
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                当您使用“Paper”相关服务时，我们会在您的移动设备上存储名为Cookie的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，我们能够收集、标识您访问、使用本产品时的信息。我们承诺，不会将Cookie 用于本隐私政策所述目的之外的任何其他用途。我们使用Cookie和同类技术主要为了实现以下功能或服务:
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                1、确保产品正常运转，保障产品与服务的安全:我们会设置认证与保障安全性的Cookie，使我们确认您是否安全登录服务或者是否遇到盗用、欺诈及其他不法行为。同时这些技术还会帮助我们改进服务效率，提升登录和响应速度。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                2、为您获得更轻松的访问体验:使用此类技术可以帮助您省去重复填写个人信息、输入搜索内容的步骤和流程(示例:记录搜索、表单填写)
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                如您不想体验相关功能或服务，您可以通过清除缓存来清除cookie数据，但清除后可能无法使用由我们提供的、依赖于Cookie的服务或相应功能。
            </text>
        </div>
        <div style="margin-top: 15px; font-weight: bold;">
            <text>
                三、我们如何共享、转让、公开披露您的个人信息
                (一)共享
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                我们不会与“Paper”服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外:
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                1、在获取明确同意的情况下共享:获得您的明确同意后，我们会与其他方共享您的个人信息。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                2、在法定情形下的共享:我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
            </text>
        </div>
        <div style="margin-top: 15px; font-weight: bold;">
            <text>
                (二)转让
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外:
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                1、在获取明确同意的情况下转让:获得您的明确同意后，我们会向其他方转让您的个人信息。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                2、在涉及合并、收购或破产清算情形或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
            </text>
        </div>
        <div style="margin-top: 15px; font-weight: bold;">
            <text>
                (三)公开披露
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                我们不会向任何第三方公开披露您的个人信息，但以下情形除外:

            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                1、您自主自愿选择披露或另行授权我们披露给第三方时，我们会将您同意或选择披露的个人信息披露给您指定的第三方。

            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                2、如果我们确定您出现违反法律法规或严重违反“Paper”相关协议规则的情况，或为保护“Paper”及其关联公司用户或公众的人身财产安全免遭侵害，我们会在依据法律法规或“Paper”相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及“Paper”已对您采取的措施。

            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                (四)共享、转让、公开披露个人信息时事先征得授权同意的例外

            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意的;
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                1、与我们履行法律法规规定的义务相关；
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                2、与国家安全、国防安全有关的；
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                3、与公共安全、公共卫生、重大公共利益有关的;
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                4、与犯罪侦查、起诉、审判和判决执行等有关的;
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                5、出于维护您或其他个人的生命、财产、等重大合法权益但又很难得到本人同意的;
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道;
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                7、其他法律法规规定或司法机关要求的情形。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                根据法律规定，共享、转让、公开披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，则此类数据的保存及处理将不再另行向您通知并征得您的同意。
            </text>
        </div>
        <div style="margin-top: 15px; font-weight: bold;">
            <text>
                四、我们如何保护您的个人信息
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                （一）我们努力为用户的个人信息提供安全保障，以防止用户个人信息的非法使用、未经授权的访问和披露、泄漏、损坏和丢失。我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会使用加密技术(例如，SSL)、匿名化处理、加密存储等手段来保护您的个人信息;建立受信赖的保护机制防止个人信息遭到恶意攻击;建立访问控制机制，遵守最小权限原则，确保只有授权人员才可访问个人信息;开展安全和个人信息保护培训课程，加强员工对于个人信息保护的认识。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                （二）我们有行业先进的以数据为核心围绕数据生命周期进行的数据安全管理体系从管理机构、组织建设、制度体系、产品技术等方面多维度提升整个系统的安全性。我们建立专门的管理制度、流程和组织以保障个人信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                （三）我们会采取合理可行的措施，尽力避免收集无关的个人信息。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络我们，以便我们根据您的申请采取相应措施。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息，请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
            </text>
        </div>
        <div style="margin-top: 15px; font-weight: bold;">
            <text>
                五、您如何管理您的个人信息
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                您可以通过以下方式访问及管理您的个人信息:
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                (一)访问您的个人信息
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息:
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                账户信息-一如果您希望访问或编辑您的账户中的个人资料信息:头像、昵称、出生日期、星座、性别、常居城市、自我介绍，您可以通过点击我的-编辑资料来执行此类操作。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                如果您无法通过上述路径访问该等个人信息，您可以随时通过“意见反馈”与我们取得联系。我们将在15天内回复您的访问请求。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“(六)响应您的上述请求”中的相关安排向您提供。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                (二)更正或补充您的个人信息
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过本条“(一)访问您的个人信息”中列明的方式提出更正或补充申请。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                (三)删除您的个人信息
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                您可以通过本条“(一)访问您的个人信息”中列明的方式删除您的部分个人信息
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                在以下情形中，您可以向我们提出删除个人信息的请求:
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                1、如果我们处理个人信息的行为违反法律法规;
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                2、如果我们收集、使用您的个人信息却未征得您的明确同意:
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                3、如果我们处理个人信息的行为严重违反了与您的约定:
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                4、如果您不再使用我们的产品或服务或您主动注销了账号，
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                5、如果我们永久不再为您提供产品或服务。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                （4）改变您授权同意的范围
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                每个业务功能需要一些基本的个人信息才能得以完成(见本隐私政策“第一条”)。除此之外，对于额外个人信息的收集和使用，您可以在设置或与“Paper”客服联系给予或收回您的授权同意。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                当您收回同意后，我们将不再使用相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                (五)个人信息主体注销账户
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                您可以自行在“设置”-“账号与安全”-“账号注销”页面提交账户注销申请。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                我们按本政策收集的您的个人信息，将在您注销后立即删除或按相关法律法规的规定进行匿名化处理，但按相关法律法规或司法机关的要求需要延长保存时间的除外。在延长保存期间内，我们会以加密形式安全地存储您的个人信息并进行隔离，直至按相关法律法规或司法机关的要求可以删除或匿名化处理。
            </text>
        </div>
        <div style="margin-top: 15px; font-weight: bold;">
            <text>
                六、我们如何处理未成年人的个人信息
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                任何18岁以下的未成年人均不得创建自己的用户账户及/或使用我们的服务。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                您理解并承诺，您向我们提供的注册信息是真实的，如果通过未成年保护相关机制识别出您的账号具有未成年使用风险，我们将为您提供青少年保护服务模式。如您认为自己不是未成年人，请您申诉进行实名认证，认证通过后，我们将您的账号立即恢复至原使用状态。
            </text>
        </div>
        <div style="margin-top: 15px; font-weight: bold;">
            <text>
                七、我们存储您的个人信息的位置和方式
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                我们依照法律法规的规定，将在中华人民共和国境内运营中收集和产生的您的个人信息，以加密的方式存储在中国境内。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                我们按本政策收集的您的个人信息，将在您删除或注销后立即删除或按相关法律法规的规定进行匿名化处理，但按相关法律法规或司法机关的要求需要延长保存时间的除外。在延长保存期间内，我们会以加密形式安全地存储您的个人信息并进行隔离，直至按相关法律法规或司法机关的要求可以删除或匿名化处理。
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                当我们的产品或服务发生停止运营的情形时，我们将以推送通知、公告等形式通知您并在合理的期限内删除您的个人信息或进行匿名化处理。
            </text>
        </div>
        <div style="margin-top: 15px; font-weight: bold;">
            <text>
                八、本隐私政策如何更新
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                我们的隐私政策可能变更
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。对于重大变更，我们会提供显著的通知(包括我们会通过“Paper”公示的方式进行通知甚至向您提供弹窗提示)本政策所指的重大变更包括但不限于:
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等:
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                2、我们在控制权等方面发生重大变化，如并购重组等引起的所有者变更等:
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                3、个人信息共享、转让或公开披露的主要对象发生变化;
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                4、您参与个人信息处理方面的权利及其行使方式发生重大变化:
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                5、我们负责处理个人信息安全的责任部联络方式及投诉渠道发生变化时;
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                6、个人信息安全影响评估报告表明存在高风险时;
            </text>
        </div>
        <div style="margin-top: 15px;text-indent: 2em;">
            <text>
                我们还会将本隐私权政策的旧版本存档，供您阅读。
            </text>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style>
page {
    width: 95%;
    margin: 0px auto;
}
</style>